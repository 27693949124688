import * as React from "react";

export interface IHamburgerMenuProps {
    showSidebar: () => void;
}

export function HamburgerMenu({ showSidebar }: IHamburgerMenuProps) {
    return (
        <button
            className="absolute z-50 w-14 h-14 m-2 right-0 bottom-0 bg-ava-primary rounded-lg flex justify-center items-center"
            type="button"
            onClick={() => showSidebar()}
        >
            <svg className="w-1/2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
        </button>
    );
}
